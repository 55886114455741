import React, { useEffect, useState } from 'react';

const Interfaz = ({ subscriptionId, subscriptionDate }) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPremium, setIsPremium] = useState(false); 

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      if (!subscriptionId) {
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`http://localhost:5000/get-subscription/${subscriptionId}`);
        if (!response.ok) {
          throw new Error('Error al obtener los datos de la suscripción: ' + response.statusText);
        }
        const data = await response.json();
        setSubscriptionData(data);

        // Si la suscripción está activa, el usuario es premium
        if (data.status === 'active') {
          setIsPremium(true);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, [subscriptionId]);

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Detalles de la Suscripción</h2>
      <p>¿Es Premium? {isPremium ? 'Sí' : 'No'}</p>
      {subscriptionData && (
        <div>
          <p>Status: {subscriptionData.status}</p>
          <p>Fecha de fin del periodo actual: {subscriptionData.current_period_end ? new Date(subscriptionData.current_period_end * 1000).toLocaleDateString() : 'No disponible'}</p>
        </div>
      )}
    </div>
  );
};

export default Interfaz;
