import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Screener = () => {
  const [files, setFiles] = useState([]);
  const [stockTableData, setStockTableData] = useState([]);
  const navigate = useNavigate();

  // Función para obtener la lista de archivos
  const fetchFiles = async () => {
    try {
      const response = await axios.get('http://localhost:5000/files');
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  // Función para obtener los datos de un archivo específico
  const fetchFileData = async (filename) => {
    const cachedData = localStorage.getItem(filename);
    if (cachedData) {
      return JSON.parse(cachedData);
    }

    try {
      const response = await axios.get(`http://localhost:5000/search/${filename}`);
      const fileData = response.data;

      const dcfSheet = fileData.find(sheet => sheet.sheetName === 'DCF');
      if (!dcfSheet || !dcfSheet.data || dcfSheet.data.length < 12) {
        return null;
      }

      const precioObjetivo = dcfSheet.data[11][1];
      const [ticker, nombreConExtension] = filename.split(' - ');
      const nombre = nombreConExtension.replace('.xlsx', '');

      const precioActual = await fetchStockPrice(ticker);
      const marketCap = await fetchMarketCap(ticker);

      if (precioActual === null || marketCap === null) {
        return null;
      }

      const upside = precioObjetivo ? ((precioObjetivo - precioActual) / precioActual * 100).toFixed(2) : null;

      const stockData = { ticker, nombre, precioObjetivo, precioActual, upside, marketCap };

      localStorage.setItem(filename, JSON.stringify(stockData));
      return stockData;
    } catch (error) {
      console.error('Error fetching file data:', error);
      return null;
    }
  };

  // Función para obtener el precio de una acción
  const fetchStockPrice = async (ticker) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/stocks/${ticker}`);
      return response.data.price;
    } catch (error) {
      console.error('Error fetching stock price:', error);
      return null;
    }
  };

  // Función para obtener la capitalización de mercado
  const fetchMarketCap = async (ticker) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/stocks/${ticker}`);
      return response.data.marketCap;
    } catch (error) {
      console.error('Error fetching market cap:', error);
      return null;
    }
  };

  // Hook para cargar los archivos cuando el componente se monta
  useEffect(() => {
    fetchFiles();
  }, []);

  // Hook para cargar los datos de los archivos y filtrarlos
  useEffect(() => {
    const loadData = async () => {
      const stockData = await Promise.all(files.map(file => fetchFileData(file)));
      const filteredData = stockData.filter(data => data !== null && data.upside > 0);
      const sortedData = filteredData.sort((a, b) => (b.upside - a.upside)).slice(0, 20);
      setStockTableData(sortedData);
    };

    if (files.length > 0) {
      loadData();
    }
  }, [files]);

  // Función para manejar el clic en una fila de stock
  const handleRowClick = (stock) => {
    const stockUrl = `${stock.ticker} - ${stock.nombre}`.replace(/ /g, '%20'); 
    console.log("Redirigiendo a:", stockUrl);
    navigate(`/search/${stockUrl}`); 
  };

  return (
    <div className="screener-container">
      <h1>Top 20 Acciones por Upside</h1>
      
      {stockTableData.length > 0 && (
        <div className="stock-table">
          <table>
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Nombre</th>
                <th>Precio Objetivo</th>
                <th>Precio Actual</th>
                <th>Upside</th>
                <th>Market Cap</th>
              </tr>
            </thead>
            <tbody>
              {stockTableData.map((stock, index) => (
                <tr key={index} onClick={() => handleRowClick(stock)} style={{ cursor: 'pointer' }}>
                  <td>{stock.ticker}</td>
                  <td>{stock.nombre}</td>
                  <td>{stock.precioObjetivo}</td>
                  <td>{stock.precioActual}</td>
                  <td style={{ color: stock.upside > 0 ? 'green' : 'red' }}>
                    {stock.upside}%
                  </td>
                  <td>{stock.marketCap}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Screener;
