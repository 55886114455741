import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios'; 
import { auth } from './firebaseConfig'; 
import Header from './components/Header'; 
import Login from './components/Login'; 
import Register from './components/Register'; 
import Main from './components/Main'; 
import StockDetail from './components/StockDetail'; 
import Payment from './components/Payment'; 
import Interfaz from './components/Interfaz'; 
import Portfolio from './components/Portfolio'; 
import { AuthProvider, useAuth } from './context/AuthContext'; 
import Screener from './components/Screener';


const App = () => {
  const [data, setData] = useState([]);
  const [portfolio, setPortfolio] = useState([]); 

  const handleSearch = async (query) => {
    try {
      const response = await fetch(`http://localhost:5000/search/${query}`); 
      if (!response.ok) {
        throw new Error('Error en la búsqueda');
      }
      const resultData = await response.json(); 
      setData(resultData); 
    } catch (error) {
      console.error('Error al buscar el archivo:', error);
      setData([]); 
    }
  };

  // Función para agregar un stock al portafolio
  const addToPortfolio = (stock) => {
    setPortfolio((prevPortfolio) => [...prevPortfolio, stock]); 

    
    axios.post('http://localhost:5000/api/portfolio', stock)
      .then(response => console.log("Stock añadido al portafolio:", response.data))
      .catch(error => console.error("Error al añadir al portafolio:", error));
  };
  

  return (
    <AuthProvider> {/* Aquí envolvemos la app dentro del proveedor de autenticación */}
      <Router>
        <Header onSearch={handleSearch} /> {/* Encabezado con autenticación y búsqueda */}
        <Routes>
          <Route path="/" element={<Main />} /> {/* Ruta principal */}
          <Route path="/login" element={<Login />} /> {/* Ruta de inicio de sesión */}
          <Route path="/register" element={<Register />} /> {/* Ruta de registro */}
          <Route path="/search/:stockName" element={<StockDetail data={data}></StockDetail>} /> {/* Ruta para detalles del stock */}
          <Route path="/portfolio" element={<Portfolio portfolio={portfolio} />} /> {/* Ruta para el portafolio */}
          <Route path="/payment" element={<Payment />} /> {/* Ruta para la página de pagos */}
          <Route path="/interfaz" element={<InterfazWrapper />} /> {/* Ruta para la interfaz */}
          <Route path="/screener" element={<Screener addToPortfolio={addToPortfolio} />} />
          <Route path="/" element={<Screener />} />
          <Route path="/search/:stockUrl" element={<StockDetail />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Wrapper para el componente Interfaz que usa el contexto de autenticación
const InterfazWrapper = () => {
  const { user } = useAuth(); 

  return (
    <Interfaz
      userId={user ? user.uid : null}
      subscriptionId={user ? user.subscriptionId : null}
    />
  );
};

export default App;
