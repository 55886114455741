import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchBar.css';

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [filteredFiles, setFilteredFiles] = useState([]);
  const navigate = useNavigate(); 

  // Función para obtener los nombres de archivos desde el servidor
  const fetchFileNames = async () => {
    try {
      const response = await fetch('http://localhost:5000/files');
      const data = await response.json();
      setFileNames(data); 
    } catch (error) {
      console.error('Error fetching file names:', error);
    }
  };

  useEffect(() => {
    fetchFileNames(); 
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fullFileName = `${query}.xlsx`; 

    if (query) {
      onSearch(fullFileName); 
      navigate(`/search/${query}`); 
      setFilteredFiles([]); 
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    
    setFilteredFiles(fileNames.filter(fileName => 
      fileName.toLowerCase().includes(value.toLowerCase())
    ).slice(0, 5)); 
  };

  const handleKeyDown = (e) => {
    
    if (e.key === 'Enter' && filteredFiles.length > 0) {
      const firstSuggestion = filteredFiles[0].replace('.xlsx', ''); 
      setQuery(firstSuggestion); 
      
      onSearch(`${firstSuggestion}.xlsx`);
      navigate(`/search/${firstSuggestion}`); 
      setFilteredFiles([]); 
    }
  };

  const handleSuggestionClick = (fileName) => {
    const nameWithoutExtension = fileName.replace('.xlsx', ''); 
    setQuery(nameWithoutExtension); 
    setFilteredFiles([]); 
  };

  return (
    <div className="search-container">
      <form onSubmit={handleSubmit} className="search-form">
        <input
          type="text"
          value={query}
          onChange={handleChange}
          onKeyDown={handleKeyDown} 
          placeholder="Buscar stock..."
          className="search-input"
        />
        <button type="submit" className="search-button">Buscar</button>
      </form>
      {filteredFiles.length > 0 && (
        <div className="suggestions-container">
          <ul className="suggestions-list">
            {filteredFiles.map((fileName, index) => (
              <li 
                key={index} 
                onClick={() => handleSuggestionClick(fileName)} 
                className="suggestion-item"
              >
                {fileName.replace('.xlsx', '')}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
