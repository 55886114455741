import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Payment = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#121212', 
    },
    formContainer: {
      backgroundColor: '#1e1e1e', 
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
      width: '400px', 
    },
    title: {
      color: '#ffffff', 
      marginBottom: '15px', 
    },
    benefitsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px', 
      marginBottom: '15px',
    },
    benefitCard: {
      backgroundColor: '#292929', 
      padding: '15px',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      width: '100%', 
      textAlign: 'left',
    },
    benefitTitle: {
      color: '#ffffff', 
      fontWeight: 'bold',
      marginBottom: '5px', 
    },
    benefitDescription: {
      color: '#cccccc', 
    },
    button: {
      padding: '10px 15px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
      width: '100%',
      margin: '10px 0', 
    },
    loadingButton: {
      backgroundColor: '#007bff',
      cursor: 'not-allowed', 
    },
    error: {
      color: '#f44336', 
      margin: '10px 0',
    },
  };

  const handlePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://localhost:5000/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }), 
      });

      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`Error en la respuesta del servidor: ${errorDetails}`);
      }

      const { id } = await response.json();

      if (!id) {
        throw new Error('ID de sesión no válido');
      }

      const stripe = window.Stripe('pk_test_51Q4o9MCDfcrREdokxMekxWx8oybzBrDUw5SabsX53y0Cnu250Vc3kUYnVskigadsEapGCjyPHDdxNwsu6b2rB0kH00zGBk8pOO');
      const { error } = await stripe.redirectToCheckout({ sessionId: id });

      if (error) {
        console.error('Error al redireccionar a Stripe Checkout:', error);
        alert(error.message);
      }
    } catch (error) {
      console.error('Error en el proceso de pago:', error);
      alert('Hubo un problema procesando el pago: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return <div style={{ color: '#ffffff' }}>Por favor, inicia sesión para acceder a la página de pagos.</div>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.formContainer}>
        <h2 style={styles.title}>Beneficios de la Suscripción Premium</h2>
        <div style={styles.benefitsContainer}>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Acceso a informes exclusivos</div>
            <div style={styles.benefitDescription}>Obtén análisis detallados que no están disponibles para los usuarios regulares.</div>
          </div>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Actualizaciones de mercado en tiempo real</div>
            <div style={styles.benefitDescription}>Recibe alertas instantáneas sobre movimientos de mercado relevantes.</div>
          </div>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Asesoría personalizada</div>
            <div style={styles.benefitDescription}>Disfruta de asesoría adaptada a tus necesidades y objetivos financieros.</div>
          </div>
          <div style={styles.benefitCard}>
            <div style={styles.benefitTitle}>Descuentos en futuras compras</div>
            <div style={styles.benefitDescription}>Accede a descuentos exclusivos en productos y servicios adicionales.</div>
          </div>
        </div>
        <p style={{ color: '#ffffff' }}>¡Obtén todas estas ventajas por solo <strong>$9.99 al mes</strong>!</p>
        <p style={{ color: '#ffffff' }}>Bienvenido, <strong>{user.email}</strong>!</p>
        <button
          style={loading ? { ...styles.button, ...styles.loadingButton } : styles.button}
          onClick={handlePayment}
          disabled={loading}
        >
          {loading ? 'Procesando...' : 'Pagar Suscripción Premium'}
        </button>
      </div>
    </div>
  );
};

export default Payment;
