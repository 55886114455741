import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { doc, collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import axios from 'axios'; 

const Portfolio = () => {
    const { user } = useAuth();
    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPortfolio = async () => {
            if (!user) {
                setError('Debes estar autenticado para ver tu portafolio.');
                setLoading(false);
                return;
            }

            try {
                const stocksRef = collection(db, 'portfolios', user.uid, 'stocks');
                const stocksSnapshot = await getDocs(stocksRef);

                
                const stocksData = await Promise.all(stocksSnapshot.docs.map(async (doc) => {
                    const stockData = doc.data();
                    let additionalData = {};
                    
                    try {
                        
                        const response = await axios.get(`http://localhost:5000/api/stocks/${stockData.ticker}`);
                        const { price, volume, peRatio, dividendRate, eps, bookValue, marketCap } = response.data;
                        
                        additionalData = {
                            currentPrice: price || 'No disponible',
                            volume: volume || 'No disponible',
                            peRatio: peRatio || 'No disponible',
                            dividendRate: dividendRate || 'No disponible',
                            eps: eps || 'No disponible',
                            bookValue: bookValue || 'No disponible',
                            marketCap: marketCap || 'No disponible',
                        };
                    } catch (err) {
                        console.error(`Error al obtener los datos del stock ${stockData.ticker}:`, err);
                        additionalData = { error: 'Error al obtener los datos.' };
                    }

                    return {
                        id: doc.id,
                        ...stockData,
                        ...additionalData,
                    };
                }));

                setStocks(stocksData);
            } catch (err) {
                console.error('Error al recuperar el portafolio:', err);
                setError('Error al recuperar el portafolio: ' + err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchPortfolio();
    }, [user]);

    if (loading) return <div>Cargando...</div>;
    if (error) return <div className="error">{error}</div>;

    return (
        <div className="portfolio-container">
            <h1>Tu Portafolio</h1>
            {stocks.length === 0 ? (
                <p>No tienes stocks en tu portafolio.</p>
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Ticker</th>
                            <th>Nombre</th>
                            <th>Precio Objetivo</th>
                            <th>Precio Actual</th>
                            <th>Volumen</th>
                            <th>PE Ratio</th>
                            <th>Dividend Yield</th>
                            <th>EPS</th>
                            <th>Book Value</th>
                            <th>Market Cap</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stocks.map(stock => (
                            <tr key={stock.id}>
                                <td>{stock.ticker}</td>
                                <td>{stock.name}</td>
                                <td>${stock.targetPrice.toFixed(2)}</td>
                                <td>{stock.currentPrice !== 'No disponible' ? `$${stock.currentPrice}` : 'No disponible'}</td>
                                <td>{stock.volume}</td>
                                <td>{stock.peRatio}</td>
                                <td>{stock.dividendRate}</td>
                                <td>{stock.eps}</td>
                                <td>{stock.bookValue}</td>
                                <td>{stock.marketCap}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default Portfolio;
