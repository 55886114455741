// Importa las funciones que necesitas del SDK
import { initializeApp } from "firebase/app"; 
import { getAnalytics } from "firebase/analytics"; 
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBN9xZcAvZIK7T0KF0w90PTtPWldVnHeR4",
  authDomain: "datosfinancieros-707fa.firebaseapp.com",
  projectId: "datosfinancieros-707fa",
  storageBucket: "datosfinancieros-707fa.appspot.com",
  messagingSenderId: "1037846311892",
  appId: "1:1037846311892:web:c0b14f4c555d7488535c41",
  measurementId: "G-LPH4SV8YVK"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); 
const analytics = getAnalytics(app); 
const provider = new GoogleAuthProvider();
const db = getFirestore(app);



export { auth, provider, db }; 
