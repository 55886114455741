import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer } from 'recharts';

// Componente para cada gráfico
const DataChart = ({ accountName, values, fiscalPeriods }) => {
  if (!Array.isArray(values) || values.length === 0 || fiscalPeriods.length === 0) {
    return <div>No hay datos disponibles para {accountName}.</div>;
  }

  const chartData = values.map((value, index) => ({
    fiscalPeriod: fiscalPeriods[index],
    value: value,
  }));

  const minY = Math.min(...chartData.map(data => data.value));
  const maxY = Math.max(...chartData.map(data => data.value));
  const adjustedMinY = Math.min(0, minY - (maxY - minY) * 0.1);
  const adjustedMaxY = maxY + (maxY - minY) * 0.1;

  return (
    <div style={{ marginBottom: '20px', border: '1px solid #ddd', borderRadius: '8px', padding: '10px', background: '#000', color: '#fff' }}>
      <h3 style={{ fontSize: '1.1rem', color: '#fff' }}>{accountName}</h3>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={chartData} style={{ background: '#000' }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" />
          <XAxis dataKey="fiscalPeriod" tick={{ fill: '#fff' }} />
          <YAxis domain={[adjustedMinY, adjustedMaxY]} tickFormatter={value => `$ ${value}`} tick={{ fill: '#fff' }} />
          <Tooltip formatter={(value) => [`$ ${value}`, 'Value']} 
                   contentStyle={{ backgroundColor: '#fff', border: '1px solid #ccc' }} 
                   labelStyle={{ fontWeight: 'bold' }} />
          <Legend wrapperStyle={{ paddingTop: '10px', color: '#fff' }} />
          <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={{ r: 3 }} activeDot={{ r: 5 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

// Componente principal para mostrar los gráficos
const ChartComponent = ({ data }) => {
  if (!data || typeof data !== 'object' || Object.keys(data).length === 0) {
    return <div>No hay datos disponibles.</div>;
  }

  const fiscalPeriods = Object.values(data).flatMap(account => {
    const valuesArray = Object.values(account);
    return valuesArray.length > 0 && Array.isArray(valuesArray[0])
      ? valuesArray[0]
      : [];
  });

  const processedData = Object.entries(data).flatMap(([accountName, values]) => {
    console.log(`Procesando cuenta: ${accountName}`, values);
    if (typeof values === 'object' && values !== null) {
      return Object.entries(values).flatMap(([label, valueArray]) => {
        console.log(`Procesando label: ${label}`, valueArray);
        if (!Array.isArray(valueArray)) {
          console.warn(`Elemento no válido para ${accountName} - ${label}:`, valueArray);
          return null;
        }

        // Convertir strings a números
        const numericValues = valueArray.map(value => {
          const parsedValue = parseFloat(value); 
          return isNaN(parsedValue) ? null : parsedValue; 
        });

        // Filtrar solo números válidos
        const validValues = numericValues.filter(value => value !== null);
        console.log(`Valores procesados para ${accountName} - ${label}:`, validValues);
        
        const cleanName = label.replace(/^\d+\s*-\s*/, '').replace(/^\d+\s*/, '').trim();
        return { name: `${accountName} - ${cleanName}`, values: validValues };
      }).filter(Boolean);
    }
    return { name: 'Elemento no válido', values: [] };
  });

  const finalProcessedData = processedData.filter(item => !item.name.includes('Fiscal Period'));

  if (finalProcessedData.length === 0) {
    return <div>No hay datos procesables para mostrar.</div>;
  }

  return (
    <div>
      {finalProcessedData.map((item, index) => (
        <DataChart key={`${item.name}-${index}`} accountName={item.name} values={item.values} fiscalPeriods={fiscalPeriods} />
      ))}
    </div>
  );
};

export default ChartComponent;
