// src/components/Header.js
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig';
import SearchBar from './SearchBar';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const Header = ({ onSearch }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

 
  const handleRedirectToInterfaz = () => {
    navigate('/interfaz');
  };

  
  const handleRedirectToPortfolio = () => {
    navigate('/portfolio'); 
  };

  
  const handleRedirectToScreener = () => {
    navigate('/screener'); 
  };

  return (
    <header style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      backgroundColor: '#1e1e1e',
      color: '#ffffff',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
    }}>
      <h1 style={{ margin: 0 }}>Financial Data</h1>
      {user && <SearchBar onSearch={onSearch} />} 
      
      <nav style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/" style={{
          marginRight: '15px',
          color: '#ffffff',
          textDecoration: 'none'
        }}>Inicio</Link>
        <Link to="/payment" style={{
          marginRight: '15px',
          color: '#ffffff',
          textDecoration: 'none'
        }}>Pago</Link> 

        {user && (
          <>
            <button onClick={handleRedirectToInterfaz} style={{
              marginRight: '15px',
              backgroundColor: '#333',
              color: '#ffffff',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}>
              Cuenta
            </button>
            {/* Botón para redirigir al portafolio */}
            <button onClick={handleRedirectToPortfolio} style={{
              marginRight: '15px',
              backgroundColor: '#007BFF',
              color: '#ffffff',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}>
              Portafolio
            </button>
            {/* Botón para redirigir al screener */}
            <button onClick={handleRedirectToScreener} style={{
              marginRight: '15px',
              backgroundColor: '#28a745', // Color verde para el screener
              color: '#ffffff',
              border: 'none',
              padding: '10px 15px',
              cursor: 'pointer',
              transition: 'background-color 0.3s',
            }}>
              Screener
            </button>
          </>
        )}

        {user ? (
          <button onClick={handleLogout} style={{
            marginRight: '15px',
            backgroundColor: '#f44336',
            color: '#ffffff',
            border: 'none',
            padding: '10px 15px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}>
            Cerrar Sesión
          </button>
        ) : (
          <>
            <Link to="/login" style={{
              marginRight: '15px',
              color: '#ffffff',
              textDecoration: 'none'
            }}>Iniciar Sesión</Link>
            <Link to="/register" style={{
              color: '#ffffff',
              textDecoration: 'none'
            }}>Registrar</Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
