// src/components/Main.js
import React from 'react';
import './Main.css'; 

const Main = () => {
  return (
    <main style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Bienvenido a DatosFinancieros</h1>
        <p style={styles.description}>
          Aquí encontrarás información valiosa sobre los mercados y análisis financieros.
        </p>
      </header>
      <section style={styles.cardContainer}>
        <Card 
          title="Análisis de Stocks"
          description="Explora análisis detallados de los stocks más relevantes del mercado."
          link="/stocks"
        />
        <Card 
          title="Tendencias del Mercado"
          description="Mantente al tanto de las últimas tendencias y movimientos en el mercado."
          link="/trends"
        />
        <Card 
          title="Herramientas de Inversión"
          description="Accede a herramientas útiles que te ayudarán en tus decisiones de inversión."
          link="/tools"
        />
        <Card 
          title="Dividendos"
          description="Infórmate sobre los dividendos de las acciones y cómo afectan tus inversiones."
          link="/dividends"
        />
      </section>
    </main>
  );
};

const Card = ({ title, description, link }) => {
  return (
    <div style={styles.card}>
      <h2 style={styles.cardTitle}>{title}</h2>
      <p style={styles.cardDescription}>{description}</p>
      <a href={link} style={styles.cardLink}>Leer más</a>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa', 
    minHeight: '100vh',
  },
  header: {
    textAlign: 'center',
    marginBottom: '40px',
  },
  title: {
    color: '#343a40', 
    fontSize: '32px',
  },
  description: {
    fontSize: '18px',
    color: '#6c757d', 
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  card: {
    backgroundColor: 'white', 
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    margin: '10px',
    width: '300px',
    textAlign: 'center',
  },
  cardTitle: {
    fontSize: '24px',
    margin: '10px 0',
    color: '#343a40', 
  },
  cardDescription: {
    color: '#777', 
    margin: '10px 0',
  },
  cardLink: {
    textDecoration: 'none',
    color: '#007bff', 
    fontWeight: 'bold',
  },
  footer: {
    textAlign: 'center',
    marginTop: '40px',
    padding: '10px 0',
    backgroundColor: '#e9ecef', 
    borderTop: '1px solid #ccc',
  },
};

export default Main;
