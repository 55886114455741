import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; 
import { db } from '../firebaseConfig'; 
import { doc, setDoc, getDoc, collection, getDocs, deleteDoc  } from 'firebase/firestore';
import ChartComponent from './ChartComponent';
import './StockDetail.css';

const StockDetail = ({ data }) => {
  const { stockName } = useParams();
  const { user } = useAuth(); 
  const [currentPrice, setCurrentPrice] = useState(null);
  const [targetPrice, setTargetPrice] = useState(null);
  const [volume, setVolume] = useState(null);
  const [peRatio, setPeRatio] = useState(null);
  const [dividendRate, setDividendRate] = useState(null);
  const [eps, setEps] = useState(null);
  const [bookValue, setBookValue] = useState(null);
  const [marketCap, setMarketCap] = useState(null);
  const [error, setError] = useState('');
  const [loadingSave, setLoadingSave] = useState(false); 
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isStockInPortfolio, setIsStockInPortfolio] = useState(false);
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const [showCharts, setShowCharts] = useState(false);

  const toggleView = () => {
    setShowCharts(prev => !prev);
  };
  

  // Extraer los datos del stock desde el parámetro recibido
  const stockData = data.find(item => item.sheetName === 'Data');
  const ticker = stockData?.data[0]?.ticker[0] || 'Ticker no disponible';
  const companyName = stockData?.data[1]?.nombre[0] || 'Nombre no disponible';

  // Efecto para obtener los datos del stock desde la API
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        if (ticker && ticker !== 'Ticker no disponible') {
          const response = await axios.get(`http://localhost:5000/api/stocks/${ticker}`);

          const { price, volume, peRatio, dividendRate, eps, bookValue, marketCap } = response.data;

          if (price !== undefined) setCurrentPrice(price);
          if (volume !== undefined) setVolume(volume);
          if (peRatio !== undefined) setPeRatio(peRatio);
          if (dividendRate !== undefined) setDividendRate(dividendRate);
          if (eps !== undefined) setEps(eps);
          if (bookValue !== undefined) setBookValue(bookValue);
          if (marketCap !== undefined) setMarketCap(marketCap);
        }
      } catch (err) {
        console.error("Error fetching stock data:", err);
        setError("Error al obtener los datos del stock: " + (err.response?.data?.message || err.message));
      }
    };

    fetchStockData();
  }, [ticker]);

  useEffect(() => {
    const checkStockInPortfolio = async () => {
      if (user && ticker) {
        const stockDocRef = doc(collection(db, 'portfolios', user.uid, 'stocks'), ticker);
        const stockDoc = await getDoc(stockDocRef);
        if (stockDoc.exists()) {
          setIsStockInPortfolio(true);
        }
      }
    };

    checkStockInPortfolio();
  }, [user, ticker]); 


  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.isPremium) {
            user.isPremium = true;
          } else {
            user.isPremium = false;
          }
        }
      }
    };
  
    fetchUserData();
  }, [user]);
  

  
  useEffect(() => {
    const newFilteredData = data.filter(sheet => sheet.sheetName.toLowerCase() !== 'data');
    setFilteredData(newFilteredData);

    if (selectedSheetIndex >= newFilteredData.length) {
      setSelectedSheetIndex(0);
    }
  }, [data, selectedSheetIndex]);

  // Obtener el 'Target Price' desde la hoja DCF
  useEffect(() => {
    const dcfData = data.find(item => item.sheetName === 'DCF');
    if (dcfData) {
      const targetPriceRow = dcfData.data.find(row => Array.isArray(row) && row.includes('Target Price'));
      if (targetPriceRow && Array.isArray(targetPriceRow)) {
        const priceIndex = targetPriceRow.indexOf('Target Price');
        const price = parseFloat(targetPriceRow[priceIndex + 1]);
        if (!isNaN(price)) {
          setTargetPrice(price);
        }
      }
    }
  }, [data]);

  // Manejo de selección de hojas
  const handleSheetSelect = (index) => {
    setSelectedSheetIndex(index);
  };

  // Cálculo del upside
  const calculateUpside = () => {
    if (currentPrice && targetPrice) {
      return ((targetPrice - currentPrice) / currentPrice) * 100;
    }
    return null;
  };

  // Formato de números con dos decimales
  const formatYahooNumber = (value) => {
    return typeof value === 'number' ? value.toFixed(2) : 'No disponible';  
  };

  // Limpiar valores, eliminando puntos y comas
  const cleanValue = (value) => {
    if (typeof value === 'string') {
        value = value.replace(/,/g, ''); 

        
        const parts = value.split('.');
        if (parts.length > 2) {
            value = parts[0] + '.' + parts.slice(1).join('');
        }
    }
    return value;
};

  // Formateo de valores para su presentación
  const formatValue = (value, rowIndex) => {
    const cleanedValue = cleanValue(value);
    const isYear = /^\d{4}$/.test(cleanedValue); 

    if (isYear || rowIndex === 0) {
      return cleanedValue;
    }

    const num = parseFloat(cleanedValue);
    return !isNaN(num) ? num.toFixed(2) : cleanedValue;
  };

  const upside = calculateUpside();

  
  if (!filteredData || filteredData.length === 0) {
    return <p>No se encontraron datos.</p>;
  }

  // Filtrar datos de la hoja seleccionada
  const selectedSheet = filteredData[selectedSheetIndex];
  const filteredSheetData = selectedSheet.data.filter(row => Array.isArray(row) ? row.length > 0 : Object.values(row).some(value => Array.isArray(value) && value.length > 0));

  
  const isDCF = selectedSheet.sheetName === "DCF";

  

  // Función para guardar datos en Firestore
  const handleToggleStockInPortfolio = async () => {
    if (!user) {
      alert('Debes estar autenticado para agregar o eliminar un stock de tu portafolio.');
      return;
    }
  
    const userRef = doc(db, 'users', user.uid);
    try {
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        alert('Usuario no encontrado. Por favor, intenta nuevamente.');
        return;
      }
  
      const userData = userDoc.data();
      const isPremium = userData.isPremium;
  
      if (!isPremium) {
        const userStocksRef = collection(db, 'portfolios', user.uid, 'stocks');
        const stockSnapshot = await getDocs(userStocksRef);
        const currentStocksCount = stockSnapshot.size;
  
        if (currentStocksCount >= 5 && !isStockInPortfolio) {
          alert('Has alcanzado el límite de 5 stocks en tu portafolio. Actualiza a premium para agregar más.');
          return;
        }
      }
  
      const stockData = {
        ticker: ticker,
        name: companyName,
        targetPrice: targetPrice,
      };
  
      const stockDocRef = doc(db, 'portfolios', user.uid, 'stocks', ticker);
      setLoadingSave(true);
  
      if (isStockInPortfolio) {
        await deleteDoc(stockDocRef);
        setIsStockInPortfolio(false);
        alert(`El stock ${ticker} ha sido eliminado de tu portafolio.`);
      } else {
        await setDoc(stockDocRef, stockData);
        setIsStockInPortfolio(true);
        alert(`El stock ${ticker} ha sido agregado a tu portafolio.`);
      }
  
      setLoadingSave(false);
    } catch (err) {
      console.error("Error al guardar el stock en el portafolio:", err);
      setLoadingSave(false);
      alert("Error al guardar el stock en el portafolio: " + (err.response?.data?.message || err.message));
    }
  };

  return (
    <div className="stock-detail-container">
      <aside className="sidebar">
        <h2>Menú</h2>
        <button
          className={`sidebar-button slider-button ${showCharts ? 'show-charts' : 'show-tables'}`}
          onClick={toggleView}
        >
          {showCharts ? 'Mostrar Tablas' : 'Mostrar Gráficos'}
        </button>
        {filteredData.map((sheet, index) => {
          const isDCF = sheet.sheetName === 'DCF';
          return (
            <button
              key={index}
              onClick={() => handleSheetSelect(index)}
              className={`sidebar-button ${isDCF && !user.isPremium ? 'disabled' : ''}`}
              disabled={isDCF && !user.isPremium}
            >
              {sheet.sheetName} {isDCF && !user.isPremium ? '(Premium)' : ''}
            </button>
          );
        })}
      </aside>


      <main className="main-content">
        <div className="stock-info">
          <h2>{ticker}</h2>
          <h1>{companyName}</h1>
          <div className="stock-info-row">
            <h1>Precio Actual: {currentPrice !== null ? `$${formatYahooNumber(currentPrice)}` : 'Cargando...'}</h1>
            <h1>Precio Objetivo: {targetPrice !== null ? `$${formatYahooNumber(targetPrice)}` : 'Cargando...'}</h1>
            <h1 className={upside >= 0 ? 'upside-positive' : 'upside-negative'}>
              Upside: {upside !== null ? `${formatYahooNumber(upside)}%` : 'Cargando...'}
            </h1>
            <h1>Volumen: {volume !== null ? formatYahooNumber(volume) : 'Cargando...'}</h1>
            <h1>PE Ratio: {peRatio !== null ? formatYahooNumber(peRatio) : 'Cargando...'}</h1>
            <h1>Dividendo: {dividendRate !== null ? `$${formatYahooNumber(dividendRate)}` : 'Cargando...'}</h1>
            <h1>EPS: {eps !== null ? formatYahooNumber(eps) : 'Cargando...'}</h1>
            <h1>Valor Contable: {bookValue !== null ? `$${formatYahooNumber(bookValue)}` : 'Cargando...'}</h1>
            <h1>Market Cap: {marketCap !== null ? `${formatYahooNumber(marketCap)}` : 'Cargando...'}</h1>
            <button onClick={handleToggleStockInPortfolio} disabled={loadingSave || loadingDelete}>
              {loadingSave || loadingDelete 
                ? (loadingSave ? 'Guardando...' : 'Eliminando...') 
                : (isStockInPortfolio ? 'Quitar de Portafolio' : 'Agregar a Portafolio')}
            </button>
          </div>
        </div>
        {error && <p className="error">{error}</p>}

        <div className="data-card">
          <h3>{selectedSheet.sheetName} - Números en $ millones</h3>

          {showCharts ? (
            <ChartComponent data={filteredSheetData} /> 
          ) : (
            <div className="table-container">
              <table>
                <tbody>
                  {filteredSheetData.length > 0 ? (
                    filteredSheetData.map((row, rowIndex) => {
                      const description = Array.isArray(row) ? row[0] : Object.keys(row)[0];
                      let values = isDCF 
                        ? Array.isArray(row) 
                          ? row.slice(1) 
                          : Object.values(row).slice(1) 
                        : Array.isArray(row) 
                          ? row.slice(1) 
                          : Object.values(row)[0];

                      if (!description || (isDCF && values.length === 0)) {
                        return null;
                      }

                      return (
                        <tr key={rowIndex}>
                          {isDCF && rowIndex === 0 ? (
                            <>
                              <td>Fiscal Period</td>
                              {values.map((value, index) => (
                                <td key={index + 1}>
                                  {index === 0 ? formatValue(description, rowIndex) : formatValue(values[index - 1], rowIndex)}
                                </td>
                              ))}
                            </>
                          ) : (
                            <>
                              <td>{description}</td>
                              {values.map((value, index) => (
                                <td key={index}>
                                  {formatValue(value, rowIndex)}
                                </td>
                              ))}
                            </>
                          )}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5">No hay datos disponibles.</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};


export default StockDetail;
